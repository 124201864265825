import { useEffect, useState, useRef } from 'react';
import key from 'weak-key';
import { Container, AccordionItem } from '@geberit/gdds';

// styles
import styles from './accordion.module.scss';

// types
import type { AccordionNordicsProps } from './accordion.types';

// components
import { ContentElement } from 'components/ContentElements/ContentElement';
import { Headline } from '../headline/headline';

// utils
import { accordionClick } from 'components/ContentElementsGdds/Accordion/tracking-actions';
import { useSectionId } from 'utils/hooks/use-section-id';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useTracking } from 'utils/hooks/useTracking';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useHash } from 'utils/hooks/useHash';
import { decodingContent } from 'utils/decodingContent';
import { previewId } from 'utils/preview-id';
import { useIsDesktop } from 'components/App/SizeProvider';
import { isEmpty } from 'utils/is-empty';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { useXy } from 'utils/hooks/use-xy';

export function Accordion({
  title,
  subtitle,
  anchor,
  titleInAnchor,
  text,
  accordionItems = [],
  openMode,
  contentIndex,
  catalogName,
  isSectionReference,
}: Readonly<AccordionNordicsProps>) {
  const isXy = useXy();
  const isDesktop = useIsDesktop();
  const track = useTracking();
  const { trackClick } = useTracking2();
  const [openedItems, setOpenedItems] = useState<unknown[]>([]);
  const accordionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (openMode === 'all')
      setOpenedItems(Array.from({ length: accordionItems.length }, (_, index) => index));
    else if (openMode === 'first') setOpenedItems([0]);
    else setOpenedItems([]);
  }, [openMode]);

  const { matchIndex } = useHash(accordionItems);

  useEffect(() => {
    const openIndex = matchIndex !== -1 && matchIndex;

    if (openIndex || openIndex === 0) {
      setOpenedItems([openIndex]);
    }
  }, [matchIndex]);

  const onClick = (e, index) => {
    track.trackEvent(accordionClick('Clicks', e.currentTarget.innerText));
    trackClick({
      click_intent: 'more_info',
      click_element: 'accordion',
    });

    if (openedItems.includes(index)) setOpenedItems(openedItems.filter((item) => item !== index));
    else setOpenedItems([...openedItems, index]);
  };

  return (
    <section
      {...previewId(catalogName && !isSectionReference ? `#${catalogName}` : undefined)}
      className={classNameBuilder(styles.accordion)}
      id={useSectionId(title || '', anchor || '', titleInAnchor || false, contentIndex || 0)}
      ref={accordionRef}
    >
      <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
        <Headline
          tag="h2"
          title={title}
          subtitle={subtitle}
          text={text}
          titlePreviewId="#st_title"
          subtitlePreviewId="#st_subtitle"
          className={styles.headline}
        />
        <div {...previewId('#st_items')}>
          {accordionItems.map((accordionItem, index) => {
            let open = false;

            if (openedItems?.length === accordionItems.length || openedItems?.includes(index)) {
              open = true;
            }
            const content = !isEmpty(accordionItem.items)
              ? accordionItem.items.map((subitem, sectionIndex) => (
                  <div key={key(subitem)} className={styles.content}>
                    <ContentElement
                      {...subitem}
                      contentIndex={sectionIndex}
                      previewId={`#${sectionIndex}`}
                      childOf="accordion"
                      isChild
                    />
                  </div>
                ))
              : null;

            return (
              <div
                key={key(accordionItem)}
                className={classNameBuilder('accordionItem', styles.accordionItemElement)}
              >
                {content && (
                  <div {...previewId(`#${index}`)}>
                    <AccordionItem
                      iconAlignment="right"
                      title={decodingContent(accordionItem.label)}
                      titlePreviewId="#st_accordionLabel"
                      contentPreviewId="#st_content"
                      opened={open}
                      onTitleClick={(e) => onClick(e, index)}
                      fontSize="p1"
                      titleBold
                      titleMargin={isDesktop ? { left: '17%', right: '17%' } : {}}
                      tag={isXy ? 'h3' : undefined}
                    >
                      <div>{content}</div>
                    </AccordionItem>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
}
