import { useMemo, useEffect, useRef, useState } from 'react';
import key from 'weak-key';
import { Link, Container, Row, Col, AccordionItem } from '@geberit/gdds';

// styles
import styles from './accordion.module.scss';

// types
import type { ContentElementPayload } from 'components/ContentElements/content-elements-payload';
import type { AccordionNordicsProps } from 'components/ContentElementsGdds/NordicsAccordion/accordion.types';

// components
import { Headline, FontWeights, Formats } from 'components/ContentElements/Headline';
import { ContentElement } from 'components/ContentElements/ContentElement';
import { Accordion as NordicAccordion } from 'components/ContentElementsGdds/NordicsAccordion/accordion';

// utils
import { useSectionId } from 'utils/hooks/use-section-id';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useHash } from 'utils/hooks/useHash';
import { decodingContent } from 'utils/decodingContent';
import { useKolo } from 'utils/hooks/use-kolo';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useXy } from 'utils/hooks/use-xy';
import { accordionClick } from './tracking-actions';

export interface AccordionProps {
  title?: string;
  subtitle?: string;
  anchor?: string;
  titleInAnchor: boolean;
  items?: Array<{
    catalogName?: string;
    title: string;
    content?: ContentElementPayload[];
    type?: string;
  }>;
  openMode?: string;
  contentIndex?: number;
  catalogName?: string;
  isSectionReference?: boolean;
  backgroundColor?: string;
}

export function Accordion(props: Readonly<AccordionProps>) {
  const {
    title,
    subtitle,
    anchor = '',
    titleInAnchor,
    items = [],
    openMode,
    contentIndex,
    catalogName,
    isSectionReference,
    backgroundColor = 'white',
  } = props;

  const translate = useTranslationFunction();
  const xy = useXy();
  const track = useTracking();
  const { trackClick } = useTracking2();
  const isKolo = useKolo();
  const [openedItems, setOpenedItems] = useState<number[]>([]);
  const accordionRef = useRef<HTMLDivElement | null>(null);
  const { matchIndex } = useHash(items);
  const sectionId = useSectionId(title ?? '', anchor, titleInAnchor, contentIndex);

  useEffect(() => {
    if (openMode === 'all')
      setOpenedItems(Array.from({ length: items.length }, (_, index) => index));
    else if (openMode === 'first') setOpenedItems([0]);
    else setOpenedItems([]);
  }, [openMode]);

  const linkAction = useMemo(
    () =>
      translate(openedItems?.length > 0 ? 'group_accordion_closeall' : 'group_accordion_openall'),
    [openedItems, translate],
  );

  useEffect(() => {
    if (matchIndex > -1) {
      setOpenedItems([matchIndex]);
    }
  }, [matchIndex]);

  if (xy) {
    const nordAccordionItems: Record<string, any>[] = [];
    items?.forEach((item) => {
      nordAccordionItems.push({ items: item.content, label: item.title, type: item.type });
    });
    return (
      <NordicAccordion {...(props as AccordionNordicsProps)} accordionItems={nordAccordionItems} />
    );
  }

  const onClick = (e, index) => {
    track.trackEvent(accordionClick('Clicks', e.currentTarget.innerText));
    trackClick({
      click_intent: 'more_info',
      click_element: 'accordion',
    });

    if (openedItems.includes(index)) setOpenedItems(openedItems.filter((item) => item !== index));
    else setOpenedItems([...openedItems, index]);
  };

  const handleAllItems = () => {
    setOpenedItems((prev) => {
      if (prev.length > 0) return [];
      return Array.from({ length: items.length }, (_, index) => index);
    });
  };

  return (
    <div
      className={classNameBuilder(styles.accordion, styles[`${backgroundColor}Background`])}
      id={sectionId}
      data-preview-id={catalogName && !isSectionReference ? `#${catalogName}` : undefined}
      ref={accordionRef}
    >
      <Container maxContentWidth={buildSize(gridSizes.twoThird)}>
        {(title || subtitle) && (
          <Row>
            <Col>
              <>
                <Headline
                  format={Formats.h2}
                  title={title}
                  subtitle={subtitle}
                  titleFontWeight={FontWeights.bold}
                  subtitleFontWeight={isKolo ? FontWeights.bold : FontWeights.light}
                />
                <Link
                  onClick={handleAllItems}
                  className={styles.handleLink}
                  hasArrow
                  stylingType="primary"
                  alignByContent="right"
                  standardFontSize={false}
                >
                  <>{linkAction}</>
                </Link>
              </>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {items.map((item, index) => {
              let open = false;

              if (openedItems?.length === items.length || openedItems?.includes(index)) {
                open = true;
              }

              return (
                <div data-preview-id={isSectionReference ? '' : `#${index}`} key={key(item)}>
                  <div
                    data-preview-id={item.catalogName ? `#${item.catalogName}` : undefined}
                    className="accordionItem"
                  >
                    <AccordionItem
                      iconAlignment="right"
                      title={decodingContent(item.title)}
                      opened={open}
                      onTitleClick={(e) => onClick(e, index)}
                      fontSize="p1"
                      titleBold
                    >
                      {item.content?.map((subitem, listIndex) => (
                        <div
                          key={key(subitem)}
                          data-preview-id={`#${listIndex}`}
                          className={styles.content}
                        >
                          <ContentElement
                            // coding guideline exception: we spread all server props to the component
                            {...subitem}
                            contentIndex={listIndex}
                            contentLength={item.content?.length ?? 0}
                            childOf="accordion"
                            isChild
                          />
                        </div>
                      ))}
                    </AccordionItem>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
